.test-card-loader {
  background-color: #fff;
  padding: 8px;
  position: relative;
  border-radius: 10px;
  border: 1px solid hwb(0deg 79% 21%);
  margin-bottom: 0;
  height: 193px;
  overflow: hidden;
}

.test-card-loader:only-child {
  margin-top: 0;
}

.test-card-loader:before {
  content: '';
  /* height: 90px; */
  height: 110px;
  display: block;
  border-radius: 8px;
  background-color: #ededed;
  box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  /* box-shadow: -28px 120px 0 -28px #ededed, -28px 200px 0 -28px #ededed; */

}

.test-card-loader:after {
  content: '';
  background-color: #333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-name: loader-animate;
  animation-timing-function: linear;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

.assessment-card-loader {
  background-color: #fff;
  padding: 8px;
  position: relative;
  border-radius: 10px;
  border: 1px solid hwb(0deg 79% 21%);
  margin-bottom: 0;
  height: 334px;
  overflow: hidden;
}

.assessment-card-loader:only-child {
  margin-top: 0;
}

.assessment-card-loader:before {
  content: '';
  height: 90px;
  display: block;
  border-radius: 8px;
  background-color: #ededed;
  box-shadow: -28px 120px 0 -28px #ededed, -28px 200px 0 -28px #ededed;

}

.assessment-card-loader:after {
  content: '';
  background-color: #333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-name: loader-animate;
  animation-timing-function: linear;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 30%,
    rgba(255, 255, 255, 0) 81%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

@keyframes loader-animate {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}
