@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './styles/card-loader.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type='checkbox']:checked,
  [type='checkbox']:checked:hover,
  [type='checkbox']:checked:focus,
  [type='radio']:checked:hover,
  [type='radio']:checked:focus {
    @apply bg-green-400;
  }
  [type='radio']:checked:disabled:hover,
  [type='radio']:checked:disabled:focus {
    @apply bg-[#BDC1CC];
    @apply pointer-events-none;
  }
  [type='checkbox'],
  [type='radio'] {
    @apply focus:ring-2;
    @apply focus:ring-green-400;
    @apply focus:ring-offset-2;
    @apply checked:bg-green-400;
  }
  textarea {
    @apply focus:ring-0;
    @apply focus:ring-offset-0;
    @apply focus:border-green-400;
  }
}

html {
  font-family: 'Figtree', sans-serif;
  background-color: rgb(247 247 247);
  height: 100%;
}

.disable-select {
  user-select: none;
}

/* Custom styles for the slider thumb */
.slider-thumb-custom::-webkit-slider-thumb {
  width: 32px;
  height: 14px;
  background-image: url('assets/custom-thumb.svg'); /* Set the path to your SVG file */
  background-size: contain; /* Ensure the SVG fits within the specified dimensions */
  background-repeat: no-repeat; /* Prevent repeating the image */
  cursor: pointer;
  border: none; /* Remove any border or default styling */
  -webkit-appearance: none;
  appearance: none;
}

.slider-thumb-custom::-moz-range-thumb {
  width: 32px;
  height: 14px;
  background-image: url('assets/custom-thumb.svg'); /* Set the path to your SVG file */
  background-size: contain; /* Ensure the SVG fits within the specified dimensions */
  background-repeat: no-repeat; /* Prevent repeating the image */
  cursor: pointer;
  border: none; /* Remove any border or default styling */
  -webkit-appearance: none;
  appearance: none;
}

.slider-thumb-custom::-ms-thumb {
  width: 32px;
  height: 14px;
  background-image: url('assets/custom-thumb.svg'); /* Set the path to your SVG file */
  background-size: contain; /* Ensure the SVG fits within the specified dimensions */
  background-repeat: no-repeat; /* Prevent repeating the image */
  cursor: pointer;
  border: none; /* Remove any border or default styling */
  -webkit-appearance: none;
  appearance: none;
}


body {
  font-family: 'Figtree', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(247 247 247);
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.checkbox {
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  width: 20px;
  height: 20px;
}

/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
} */

/* ::-webkit-scrollbar-track {
  background: #e2e2e2;
} */

/* ::-webkit-scrollbar-thumb {
  background: #565656;
  border: 2px solid #e2e2e2;
  border-radius: 24px;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#beamerSelector {
  background-color: #F0FAF8;
  background-image: url("assets/v6-icon.svg");
  right: 30px;
  z-index: 0;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 26%);
}

.beamer_icon {
  background-color: #FB6232;
}

/* Style for the Rich Text Editor custom blocks */

.custom-blockquote {
  margin: 10px 0;
  padding-left: 16px;
  border-left: 2px solid #ccc;
  font-style: italic;
}

.public-DraftStyleDefault-code-block {
  font-family: monospace;
  background-color: #f0f0f0;
  padding-left: 10px;
  margin-right: 20px;
}

/* END */

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
  }
}

@keyframes slide-out-right {
  0%   {transform: translateX(100%)}
  100% {transform: translateX(0%)}
}
@keyframes slide-in-right {
  0%   {transform: translateX(0%)}
  100% {transform: translateX(100%)}
}
@keyframes slide-out-left {
  0%   {transform: translateX(-100%)}
  100% {transform: translateX(0%)}
}
@keyframes slide-in-left {
  0%   {transform: translateX(0%)}
  100% {transform: translateX(-100%)}
}